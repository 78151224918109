import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { readingTime as readingTimeHelper } from "@tryghost/helpers";
import { Tags } from "@tryghost/helpers-gatsby";
import { Layout } from "../components/common";
import { MetaData } from "../components/common/meta";

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const Post = ({ data, location }) => {
    const post = data.ghostPost;
    const readingTime = readingTimeHelper(post);
    // convert published_at date string to desired date format
    function formatDate(date) {
        let monthNumber = Number(date.substring(5, 7));
        let month = new Date();
        month.setMonth(monthNumber - 1);
        month = month.toLocaleString("default", { month: "short" });
        let day = date.substring(8, 10);
        let year = date.substring(0, 4);
        return month + " " + day + ", " + year;
    }
    const publishDate = formatDate(post.published_at);
    return (
        <>
            <MetaData data={data} location={location} type="article" />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                <div className="container">
                    <article className="content">
                        {post.feature_image ? (
                            <figure className="post-feature-image">
                                <img
                                    src={post.feature_image}
                                    alt={post.title}
                                />
                            </figure>
                        ) : null}
                        {post.tags && (
                            <div className="post-tags">
                                {" "}
                                <Tags
                                    post={post}
                                    visibility="public"
                                    autolink={false}
                                />
                            </div>
                        )}
                        <section className="post-full-content">
                            <h1 className="content-title">{post.title}</h1>
                            <p className="post-tagline">
                                {publishDate} {" - "}
                                {readingTime}
                            </p>
                            {/* The main post content */}
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                        </section>
                    </article>
                </div>
            </Layout>
        </>
    );
};

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            published_at: PropTypes.string.isRequired,
            tags: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                })
            ),
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Post;

export const postQuery = graphql`
    query ($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`;
